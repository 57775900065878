import { GameSite } from '@genteras/core-lib/constants/game-sites.constant';
import type { StaticImageData } from 'next/image';
import type { ReactNode } from 'react';
import { useLaunchGame } from '@/lib/hooks/useLaunchGame';
import CasinoIcon from '../../public/images/icon/casino.png';
import CrownIcon from '../../public/images/icon/crown.png';
import FootballIcon from '../../public/images/icon/football.png';
import LiveDealerIcon from '../../public/images/icon/live.png';
import SlotIcon from '../../public/images/icon/slot.png';
import StockIcon from '../../public/images/icon/stock.png';
import { urls } from './urls';

export type Navigation = {
  icon?: ReactNode | StaticImageData;
  title: string;
  url?: string;
  onClick?: () => void;
};

export const useNavigationMenu = () => {
  const { launchGame } = useLaunchGame();

  const navigations: { [key in string]: Navigation } = {
    liveGame: {
      icon: LiveDealerIcon,
      title: 'common:gameType.live',
      url: urls.liveGame,
    },
    slotGame: {
      icon: SlotIcon,
      title: 'common:gameType.slot',
      url: urls.slotGame,
    },
    casinoGame: {
      icon: CasinoIcon,
      title: 'common:gameType.casino',
      url: urls.casinoGame,
    },
    contact: {
      title: 'landingPage.CONTACT_US',
      // url: urls.contactUs,
      // eslint-disable-next-line
      onClick: () => (OnlineHelpAPI as any).open_chat_window(),
    },
    termsAndConditions: {
      title: 'common:termsAndConditions.title',
      url: urls.disclaimer,
    },
    binaryOptionGame: {
      icon: StockIcon,
      title: 'common:gameType.bo',
      url: urls.bo,
    },
    cockFightGame: {
      icon: CrownIcon,
      title: 'common:gameType.cockFight',
      url: urls.cockFight,
    },
    sport: {
      icon: FootballIcon,
      title: 'common:Sports',
      onClick: () => launchGame(GameSite.FM_MO, null, 'sportbook'),
    },
  };

  const mobileMenu: Navigation[][] = [
    [
      navigations.liveGame,
      navigations.slotGame,
      navigations.casinoGame,
      navigations.sport,
      navigations.binaryOptionGame,
      navigations.cockFightGame,
    ],
    [navigations.contact, navigations.termsAndConditions],
  ];

  const desktopMenu: Navigation[] = [
    navigations.liveGame,
    navigations.slotGame,
    navigations.casinoGame,
    navigations.sport,
    navigations.binaryOptionGame,
    navigations.cockFightGame,
    navigations.contact,
  ];

  return {
    desktopMenu,
    mobileMenu,
  };
};

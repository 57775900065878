import { notify } from '@genteras/ui-lib/base';
import type { TFunction } from 'next-i18next';
import { isIPad13 } from 'react-device-detect';
import GameLaunchAPI from '../api/gameLaunch';

export const windowOpenGame = async (
  gameSite: string,
  gameCode: string | null | undefined,
  gameType: string | null | undefined,
  t: TFunction,
  locale: string
) => {
  const loadingPage =
    location.protocol + '//' + location.host + '/loading.html';
  const win = window.open(loadingPage, `${gameSite}_${gameCode}_${gameType}`);
  if (!win) {
    throw new Error('Cannot open window');
  }
  win.focus();

  await GameLaunchAPI.loginGame(
    {
      platform: gameSite,
      gameCode: gameCode,
      gameType: gameType,
      isIPad: isIPad13,
    },
    locale
  )
    .then((result) => {
      if (!result.canBet) {
        // Pretend the user have some connection issue but not disabled by agent
        return;
      }

      if (result.isMaintaining) {
        win.close();
        notify.error({
          message: t('common:gameIsMaintaining') as string,
          t,
        });
        return;
      }

      if (!result.gameUrl) {
        win.close();
        notify.error({
          message: t('common:networkBusyOrMaintaining') as string,
          t,
        });
        return;
      }

      win.location.href = result.gameUrl;
    })
    .catch(() => {
      win.close();
      notify.error({
        message: t('common:networkBusyOrMaintaining') as string,
        t,
      });
    });
};

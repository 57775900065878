import {
  type GameSite,
  whichGameSite,
} from '@genteras/core-lib/constants/game-sites.constant';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useGlobalModalContext } from '../context/global-modal-context';
import { windowOpenGame } from '../utils/gameLauncher';
import useUser from './useUser';

export const useLaunchGame = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { locale } = router;
  const { user } = useUser();
  const { showLoginModal } = useGlobalModalContext();

  const openGame = async (
    gameSite: GameSite,
    gameCode?: string | null,
    gameType?: string | null
  ) => {
    if (!locale) {
      throw new Error('Locale is missing');
    }
    const openGameSite = user.gameSiteSettings.find(
      (x) => whichGameSite(gameSite) === whichGameSite(x.gameSite)
    )?.gameSite;
    if (!openGameSite) {
      return;
    }
    await windowOpenGame(openGameSite, gameCode, gameType, t, locale);
  };

  const launchGame = (
    gameSite: GameSite,
    gameCode?: string | null,
    gameType?: string | null
  ) => {
    if (!user.username) {
      return showLoginModal();
    }

    if (user.username) {
      openGame(gameSite, gameCode, gameType);
    }
  };

  return {
    launchGame,
  };
};

import styled from '@emotion/styled';
import { Button } from 'antd';

export const StyledButton = styled(Button)({
  /*
  background: 'var(--Black)',
  color: 'var(--White)',
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  borderRadius: 99,

  ['&:hover']: {
    background: 'var(--Dark-Grey)',
    borderColor: 'inherits',
  },

  [cssMedia.md]: {
    fontSize: 14,
  },
  */
});

import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import styled from '@emotion/styled';
import { cssMedia } from '@genteras/core-lib/utils/css-utils';
import { FormattedCurrency } from '@genteras/ui-lib';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Menu } from 'antd';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import Router from 'next/router';
import type { FC } from 'react';
import { useSWRConfig } from 'swr';
import { urls } from 'constant/urls';
import UserAPI from '../../lib/api/user';
import useUser from '../../lib/hooks/useUser';
import { commonConfig } from '../../lib/i18n/configs';
import { AccountStatus } from './Modal';

const UserMenu = styled(Menu)({
  marginTop: '10px',
  borderRadius: '15px 15px',
  borderWidth: '1px',
  borderColor: 'green',
});

const UserButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;
const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
`;

const UserIcon = styled(UserOutlined)({
  border: '1px solid',
  borderRadius: 99,
  padding: 5,
  display: 'none',
  [cssMedia.md]: {
    display: 'initial',
  },
});
export const UserMenuDropdown: FC = () => {
  const { t } = useTranslation(commonConfig.i18nNamespaces);
  const { user } = useUser();
  const { mutate } = useSWRConfig();

  const logout = async () => {
    await UserAPI.logout();
    document.cookie =
      'connect.sid=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    await mutate('/players/me', null);
    Router.push(urls.home).then(() => location.reload());
  };

  const Btn = styled(Button)({
    borderColor: 'green',
    borderWidth: '0px',
    ['&:hover, &:focus']: {
      background: 'transparent',
      border: 'none',
    },
    height: 'auto',
    padding: '5px',
  });

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') logout();
  };

  const MenuItems = [
    {
      key: 1,
      label: <Link href={urls.betRecord}>{t('common:header.betRecord')}</Link>,
    },
    {
      key: 2,
      label: (
        <Link href={urls.memberReport}>{t('common:header.memberReport')}</Link>
      ),
    },
    {
      key: 3,
      label: <Link href={urls.changePassword}>{t('common:Setting')}</Link>,
    },
    {
      key: 'logout',
      label: t('common:header.logout'),
    },
  ];

  const menu = <UserMenu items={MenuItems} onClick={handleMenuClick} />;

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={['click']}
      overlayClassName={''}
    >
      <Btn
        css={{
          background: 'transparent',
          borderColor:
            user.overallStatus == AccountStatus.FROZEN
              ? 'red'
              : 'var(--primaryColor)',
        }}
      >
        <UserButton>
          <UserInfo>
            <span className="body3">{user.username}</span>
            <FormattedCurrency
              value={user.playerWallet.balance}
              theme="initial"
              className="subtitle2"
            />
          </UserInfo>
          <UserIcon
            css={{
              margin: 'auto 0',
              fontSize: '18px',
              color:
                user.overallStatus == AccountStatus.FROZEN
                  ? 'red'
                  : 'var(--White)',
            }}
          />
        </UserButton>
      </Btn>
    </Dropdown>
  );
};

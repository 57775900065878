import styled from '@emotion/styled';
import { cssMedia } from '@genteras/core-lib/utils/css-utils';
import { Button, Grid, Modal } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useGlobalModalContext } from '@/lib/context/global-modal-context';
import { urls } from 'constant/urls';
import type { SiteConfig } from '../../config';
import config, { Sites } from '../../config';
import useUser from '../../lib/hooks/useUser';
import FrozenAccountAlert from '../common/FrozenAccountAlert';
import FrozenAlert from '../common/FrozenAlert';
import { LanguageDropdown } from '../common/LanguageDropdown';
import { MenuDrawer } from '../common/MenuDrawer';
import { AccountStatus, popupModalCss } from '../common/Modal';
import { NavigationBar } from '../common/NavigationBar';
import { UserMenuDropdown } from '../common/UserMenuDropdown';

const { useBreakpoint } = Grid;

const HeaderContainer = styled.div`
  height: 64px;
`;
const HeaderWrapper = styled(Header)({
  padding: '0 20px',
  [cssMedia.lg]: {
    padding: '0 10px',
  },
});

let isFirstLoad = false;

export const MainHeader: FC = () => {
  const { user, isLoading } = useUser();
  const router = useRouter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showOnce, setShowOnce] = useState(false);
  const { showLoginModal } = useGlobalModalContext();
  const { t } = useTranslation();

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeader: '',
    logoCompact: '',
    favicon: '',
    systemIcon: '',
  });

  const { lg } = useBreakpoint();
  useEffect(() => {
    if (isLoading) return;
    if (
      user.id &&
      !user.passwordLastReset &&
      !router.pathname.includes('reset-password')
    ) {
      router.push(urls.resetPassword);
    }
  }, [user, isLoading, router]);

  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);

  if (
    !isModalVisible &&
    user.overallStatus == AccountStatus.FROZEN &&
    !showOnce &&
    !isFirstLoad
  ) {
    setIsModalVisible(true);
    setShowOnce(true);
    setTimeout(() => {
      isFirstLoad = true;
    }, 100);
  }

  if (showOnce && user.overallStatus != AccountStatus.FROZEN) {
    setShowOnce(false);
  }

  const handleClickLogo = () => {
    router.push(urls.home);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const Logo = styled.img({
    cursor: 'pointer',
    maxHeight: 50,
    width: '120px',
    height: '40px',
    // [cssMedia.xs]: {
    //   content: `url(${siteConfig.favicon})`,
    //   width: 31,
    //   height: 31,
    // },
  });

  function LogoImage() {
    const props = {
      src: siteConfig.logoHeader,
      alt: 'logo',
      onClick: handleClickLogo,
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Logo {...props} />
      </div>
    );
  }

  return (
    <>
      <HeaderContainer className="header-container">
        <HeaderWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {lg ? null : <MenuDrawer />}

            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <LogoImage />
              {user ? <NavigationBar /> : null}
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              {user.overallStatus == AccountStatus.FROZEN ? (
                <FrozenAccountAlert />
              ) : null}
              {lg ? <LanguageDropdown /> : null}
              {isLoading || !user.username ? (
                <Button onClick={showLoginModal}>{t('Login')}</Button>
              ) : (
                <UserMenuDropdown />
              )}
            </div>
          </div>
        </HeaderWrapper>
      </HeaderContainer>
      <Modal
        css={popupModalCss}
        centered
        width={800}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
      >
        <FrozenAlert />
      </Modal>
    </>
  );
};

import type { StaticImageData } from 'next/image';
import SimplePlayPlatform from '../../public/images/provider/platform/simpleplay.png';

import { urls } from './urls';

export type Partner = {
  image: StaticImageData | string;
  alt: string;
  gameSite?: string;
  pageUrl?: string;
};

export type PartnerSection = {
  label: string;
  carousel?: boolean;
  items: Partner[];
};

export const platformPartners: PartnerSection = {
  label: 'slotPartners',
  items: [
    {
      image: '/images/provider/platform/sa.png',
      alt: 'sa',
      gameSite: 'GAME_SITE_SA',
      pageUrl: urls.liveGame,
    },
    {
      image: SimplePlayPlatform,
      alt: 'simpleplay',
      gameSite: 'GAME_SITE_SIMPLEPLAY',
      pageUrl: urls.jackpot,
    },
    {
      image: '/images/blackAnts/logo.svg',
      alt: 'black-ants',
      gameSite: 'BLACK_ANTS',
    },
    {
      image: '/images/provider/platform/phoenix-club.png',
      alt: 'pc',
      gameSite: '',
    },
    {
      image: '/images/provider/platform/evolution.svg',
      alt: 'evolution',
      gameSite: '',
    },
  ],
};

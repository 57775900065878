import WinsplaysFavicon from '../public/images/winsplays/favicon.png';
import WinsplaysLogoCompact from '../public/images/winsplays/logo-s.png';
import WinsplaysLogoHeader from '../public/images/winsplays/logo.png';

export enum Sites {
  BLACKANTS = 'www.blackants.com',
  AIEIA = 'www.aieia.com',
  DEFAULT = 'play.winsplays.com',
  LOCAL = 'localhost',
  SA36 = 'www.sa36.com',
}

export type SiteConfig = {
  siteName: string;
  logoHeader: string;
  logoCompact: string;
  favicon: string;
  systemIcon: string;
};

const sitesConfig: Map<Sites, SiteConfig> = new Map([
  [
    Sites.AIEIA,
    {
      siteName: 'Winsplays',
      logoHeader: WinsplaysLogoHeader.src,
      logoCompact: WinsplaysLogoCompact.src,
      favicon: WinsplaysFavicon.src,
      systemIcon: '',
    },
  ],
  [
    Sites.DEFAULT,
    {
      siteName: 'Winsplays',
      logoHeader: WinsplaysLogoHeader.src,
      logoCompact: WinsplaysLogoCompact.src,
      favicon: WinsplaysFavicon.src,
      systemIcon: '',
    },
  ],
  [
    Sites.LOCAL,
    {
      siteName: 'Winsplays',
      logoHeader: WinsplaysLogoHeader.src,
      logoCompact: WinsplaysLogoCompact.src,
      favicon: WinsplaysFavicon.src,
      systemIcon: '',
    },
  ],
  [
    Sites.SA36,
    {
      siteName: 'Winsplays',
      logoHeader: WinsplaysLogoHeader.src,
      logoCompact: WinsplaysLogoCompact.src,
      favicon: WinsplaysFavicon.src,
      systemIcon: '',
    },
  ],
  [
    Sites.BLACKANTS,
    {
      siteName: 'Winsplays',
      logoHeader: WinsplaysLogoHeader.src,
      logoCompact: WinsplaysLogoCompact.src,
      favicon: WinsplaysFavicon.src,
      systemIcon: '',
    },
  ],
]);

export default {
  sitesConfig,
};

import type { GameSite } from '@genteras/core-lib/constants/game-sites.constant';
import { axiosPlayerBase } from '../utils/axiosHelpers';

type LoginGameData = {
  platform: string;
  gameCode?: string | null;
  gameType?: string | null;
  isIPad: boolean;
};

type LoginGameResponse = {
  isMaintaining: boolean;
  canBet: boolean;
  gameUrl: string;
};
/*
type Translations = {
  lang: string;
  name: string;
  imgUrl: string;
};
*/
export type Game = {
  id: string;
  gameSite: GameSite;
  gameType: string;
  name: string;
  imgUrl: string;
  launchCode: string | null;
  betCode: string | null;
  // launchDate: string,
  rtp?: number;
  // translations: Translations[],
};

export type GameSiteLobby = {
  gameSite: GameSite;
  trialPlay: boolean;
  comingSoon: boolean;
  gameType: string;
  gameCode: string;
  image: string;
};

const GameLaunchAPI = {
  loginGame: async (
    loginGameData: LoginGameData,
    language: string
  ): Promise<LoginGameResponse> => {
    const response = await axiosPlayerBase.post(
      `/game-launch/login-game`,
      JSON.stringify(loginGameData),
      {
        headers: {
          'Accept-Language': language.replace('-', '_'),
        },
      }
    );
    const { data, status } = response;
    if (status >= 400) {
      throw new Error(data.message);
    }

    return response.data;
  },
};

export default GameLaunchAPI;

import styled from '@emotion/styled';
import { Col, Row } from 'antd';
import cx from 'classnames';
import Image from 'next/image';
import type { Partner as IPartner, PartnerSection } from 'constant/partners';
import { urls } from 'constant/urls';

// const PartnerSectionContainer = styled.div`
//     display: flex;
//     align-items: flex-start;
//     align-content: flex-start;
//     gap: 4px;
//     flex-wrap: wrap;
// `

const PartnerSectionContainer = styled(Row)``;

const ImageContainer = styled(Col)`
  width: 77px;
  height: 32px;
  position: relative;
`;

const Partner = ({ partner }: { partner: IPartner }) => {
  const { image, gameSite = '' } = partner;

  const getPathFromPathname = (url: string, pathname: string) => {
    const path = `/${pathname.split('/').slice(1, 2).join('/')}/${url}`;
    return path.endsWith('/') ? path.slice(0, -1) : path;
  };

  // const handleLogoOnClick = (url: string | undefined) => {};

  const url = gameSite
    ? getPathFromPathname(
        `${urls.slotGame}/${gameSite.replace('GAME_SITE_', '').toLowerCase()}`,
        '' // pathname
      )
    : undefined;

  const getImageSource = () => {
    if (typeof image === 'string') return image;
    return image.src;
  };
  return (
    <ImageContainer xs={8}>
      <Image
        src={getImageSource()}
        loading="lazy"
        layout="fill"
        objectFit="contain"
        // onClick={() => handleLogoOnClick(url)}
        className={cx({ 'cursor-pointer': !!url })}
      />
    </ImageContainer>
  );
};

export const PartnerContainer = ({ partner }: { partner: PartnerSection }) => {
  const { label, items } = partner;

  return (
    <PartnerSectionContainer gutter={[8, 8]}>
      {items.map((item) => (
        <Partner key={`${label}-${item.alt}`} partner={item} />
      ))}
    </PartnerSectionContainer>
  );
};

import { GlobalOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import type { MenuProps } from 'antd';
import { Dropdown, Menu } from 'antd';
import { setCookie } from 'cookies-next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { langauges } from '@/lib/i18n/Language';
import type { SiteConfig } from '../../config';
import config, { Sites } from '../../config';

const LanguageDd = styled(Dropdown)({
  display: 'flex',
  alignItems: 'center',
  height: 30,
  borderRadius: '15px 15px',
  width: 70,
  border: '1px solid var(--primaryColor)',
});
const LanguageCtn = styled.span({
  cursor: 'pointer',
  color: 'var(--White)',
  height: 30,
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
});
const GlobalOutlinedIcon = styled(GlobalOutlined)({
  color: 'var(--White)',
  fontSize: 25,
  marginRight: 5,
});

export const LanguageDropdown: FC = () => {
  const router = useRouter();
  const { locale, pathname } = router;

  const [siteConfig, setSiteConfig] = useState<SiteConfig>({
    siteName: '',
    logoHeader: '',
    logoCompact: '',
    favicon: '',
    systemIcon: '',
  });

  useEffect(() => {
    setSiteConfig(
      config.sitesConfig.get(window.location.hostname as Sites) ||
        config.sitesConfig.get(Sites.DEFAULT)!
    );
  }, []);

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    setCookie('NEXT_LOCALE', key);
  };

  const MenuItems = langauges.map((locale) => ({
    key: locale.id,
    id: locale.id,
    label: (
      <Link href={pathname} locale={locale.locale}>
        <a>{locale.title}</a>
      </Link>
    ),
  }));

  const languageMenu = <Menu items={MenuItems} onClick={handleMenuClick} />;

  const siteName = siteConfig.siteName || '';
  return (
    <LanguageDd
      overlayClassName={
        siteName === 'SA36'
          ? 'sa36'
          : siteName === 'BlackAnts'
          ? 'blackants'
          : ''
      }
      overlay={languageMenu}
      placement="bottomRight"
    >
      <LanguageCtn>
        <GlobalOutlinedIcon />
        {langauges.find((x) => x.id === locale)?.shortTitle}
      </LanguageCtn>
    </LanguageDd>
  );
};

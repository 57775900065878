import styled from '@emotion/styled';
import { cssMedia } from '@genteras/core-lib/utils/css-utils';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useNavigationMenu } from 'constant/useNavigationMenu';

const Container = styled.div({
  display: 'none',
  height: '100%',

  [cssMedia.md]: {
    display: 'flex',
    alignItems: 'center',
  },
});
export const NavigationBar = () => {
  const { t } = useTranslation();
  const { desktopMenu } = useNavigationMenu();

  return (
    <Container style={{ whiteSpace: 'nowrap' }}>
      {desktopMenu.map((nav) => {
        return nav.onClick ? (
          <a
            key={`nav-${nav.title}`}
            onClick={() => nav.onClick && nav.onClick()}
            href={'javascript:void(0)'}
          >
            {t(nav.title)}
          </a>
        ) : (
          <Link key={`nav-${nav.title}`} href={nav.url ?? ''}>
            {t(nav.title)}
          </Link>
        );
      })}
    </Container>
  );
};

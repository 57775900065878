import { MenuOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { cssMedia } from '@genteras/core-lib/utils/css-utils';
import { Divider as DividerBase, Drawer as DrawerBase } from 'antd';
import { useTranslation } from 'next-i18next';
import type { StaticImageData } from 'next/image';
import Image from 'next/image';
import Router, { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { isValidElement, useState } from 'react';
import { mutate } from 'swr';
import UserAPI from '@/lib/api/user';
import useUser from '@/lib/hooks/useUser';
import { urls } from 'constant/urls';
import type { Navigation } from 'constant/useNavigationMenu';
import { useNavigationMenu } from 'constant/useNavigationMenu';
import { StyledButton } from '../base/StyledButton';
import { LanguageDropdown } from './LanguageDropdown';

const Drawer = styled(DrawerBase)({});

const DrawerItem = styled.div`
  display: flex;
  padding: 16px 0px;
  align-items: center;
  gap: 8px;
`;

const DrawerItemIcon = styled(Image)({});

const Divider = styled(DividerBase)({
  border: '0.5px solid white',
});

const MenuIcon = styled(MenuOutlined)({
  fontSize: '24px',
  color: 'var(--White)',
  [cssMedia.md]: {
    display: 'none',
  },
});

const FooterButton = styled(StyledButton)({
  width: '100%',
});

export const MenuDrawer: FC = () => {
  const [open, setOpen] = useState(false);
  const { mobileMenu } = useNavigationMenu();

  const { t } = useTranslation();
  const router = useRouter();
  const { user } = useUser();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const navigate = (nav: Navigation) => {
    if (nav.onClick) {
      nav.onClick();
      onClose();
    }
    if (nav.url) {
      router.push(nav.url);
      onClose();
    }
  };

  const getMenuItems = () => {
    const getIcon = (item: ReactNode | StaticImageData) => {
      if (isValidElement(item)) return <>{item}</>;
      if (
        typeof item === 'object' &&
        item !== null &&
        'src' in item &&
        'width' in item
      ) {
        return <DrawerItemIcon src={item.src} width={24} height={24} />;
      }
      return null;
    };

    // menu.pop();
    return mobileMenu
      .map((section, index) => [
        ...section.map((nav, subIndex) => (
          <DrawerItem
            key={`menu-item-${index}-${subIndex}`}
            onClick={() => navigate(nav)}
          >
            {nav.icon ? getIcon(nav.icon) : null}
            <span className="body1">{t(nav.title)}</span>
          </DrawerItem>
        )),
      ])
      .flatMap((subArray, index) => [
        ...subArray,
        <Divider key={'divider-' + index} />,
      ]);
  };

  const logout = async () => {
    await UserAPI.logout();
    document.cookie =
      'connect.sid=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    mutate('/players/me', null);
    Router.push(urls.home).then(() => location.reload());
  };

  return (
    <>
      <MenuIcon onClick={showDrawer} />
      <Drawer
        onClose={onClose}
        visible={open}
        placement="left"
        footer={
          user.id ? (
            <FooterButton onClick={logout}>{t('Logout')}</FooterButton>
          ) : null
        }
        extra={<LanguageDropdown />}
      >
        {getMenuItems()}
      </Drawer>
    </>
  );
};
